<template>
    <div class="drainage-link">
      <div class="wx-logo">
        <img src="@/static/images/wx-logo.png">
      </div>
      <div class="btn" @click="goWx">
        打开微信
      </div>
      <div class="footer">
        <div class="logo">
          <img src="@/static/images/logo.png">
          班克微联
        </div>
        <div class="text">
          好用的企业微信管理工具
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {getScheme} from "@/api/miniProgramCorp";
  
  export default {
    data() {
      return {
        corpId: '',
        employeeId :''
      }
    },
    mounted() {
      this.corpId = this.$route.query.corpId;
      this.employeeId  = this.$route.query.employeeId;
      this.goWx()
    },
    methods: {
      goWx() {
        if (!this.corpId) return false
        getScheme({corpId:this.corpId,employeeId:this.employeeId}).then(res => {
          window.location.href = res.data.openLink
        })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .drainage-link {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .wx-logo {
    width: 30%;
    margin: 0 auto;
    max-width: 102px;
  
  
    img {
      width: 100%;
    }
  }
  
  .btn {
    width: 210px;
    height: 48px;
    background: #1584ff;
    border-radius: 2px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
    color: rgba(0, 0, 0, .25);
  
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 26px;
  
      img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
  
    .text {
      margin-top: 6px;
      font-size: 13px;
    }
  }
  
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgba(0, 0, 0, .25);
    margin-top: 40rpx;
  }
  
  .footer .text {
    margin-top: 18rpx;
    font-size: 13px;
  }
  
  .footer .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 26rpx;
  }
  
  .footer .logo image {
    width: 46rpx;
    height: 46rpx;
    margin-right: 8rpx;
  }
  </style>
  